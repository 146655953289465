import React, { useState } from 'react';
import { Container, TextField, Typography, Box, Grid, Card, CardContent, IconButton, InputAdornment, Avatar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';

const Dashboard = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [cards, setCards] = useState([
    { id: 1, title: 'Análisis en Tiempo Real', description: 'Monitoreo y análisis del inventario en tiempo real.', icon: <AssessmentIcon />, link: '/analisis' },
    { id: 2, title: 'Mantenimiento de Datos', description: 'Gestión y actualización de los datos del inventario.', icon: <BuildIcon /> },
    { id: 3, title: 'Gestión de Usuarios', description: 'Administración de usuarios y permisos del sistema.', icon: <GroupIcon />, link: '/user-management' },
    { id: 4, title: 'Reporte de Inventario', description: 'Generación de reportes detallados sobre el inventario.', icon: <InventoryIcon /> },
    { id: 5, title: 'Notificaciones', description: 'Configuración de alertas y notificaciones.', icon: <NotificationsActiveIcon /> },
    { id: 6, title: 'Estadísticas de Inventario', description: 'Visualización gráfica de estadísticas del inventario.', icon: <BarChartIcon /> },
    { id: 7, title: 'Tendencias', description: 'Análisis de tendencias y patrones de inventario.', icon: <TimelineIcon /> },
  ]);

  const filteredCards = cards.filter((card) =>
    card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="xl" sx={{ mt: 8, mb: 4, p: 4, bgcolor: '#f5f5f5', borderRadius: 4, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: '#1565c0' }}>
          Dashboard de Funciones del Sistema
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ bgcolor: 'white', borderRadius: 2, boxShadow: 1 }}
        />
      </Box>
      <Grid container spacing={4}>
        {filteredCards.length > 0 ? (
          filteredCards.map((card) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={card.id}>
              <Card
                sx={{
                  boxShadow: 4,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': { transform: 'scale(1.05)', boxShadow: 8 },
                  bgcolor: '#ffffff',
                  borderRadius: 3,
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Avatar sx={{ bgcolor: '#1565c0', color: 'white', mb: 2, width: 56, height: 56, mx: 'auto' }}>
                    {card.icon}
                  </Avatar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 'bold', color: '#1565c0', cursor: card.link ? 'pointer' : 'default' }}
                    onClick={() => card.link && window.location.assign(card.link)}
                  >
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" color="text.secondary" textAlign="center">
              No se encontraron resultados.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Dashboard;
