import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import api from './api';
import JsBarcode from 'jsbarcode';
import {
  Container,
  Typography,
  Paper,
  Box,
  createTheme,
  ThemeProvider,
  Divider,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0c2340',
    },
    secondary: {
      main: '#f5f5f5',
    },
    background: {
      default: '#e5e5e5',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    h4: {
      fontWeight: 600,
      color: '#0c2340',
    },
  },
});

const ConteoDetalleTable = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [groupedData, setGroupedData] = useState([]);
  const [groupedColumns, setGroupedColumns] = useState([]);
  const [ungroupedData, setUngroupedData] = useState([]);
  const [ungroupedColumns, setUngroupedColumns] = useState([]);
  const [allArticlesData, setAllArticlesData] = useState([]);
  const [allArticlesColumns, setAllArticlesColumns] = useState([]);
  const [selectedBarcode, setSelectedBarcode] = useState('');
  const barcodeRef = useRef(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [articleModalOpen, setArticleModalOpen] = useState(false);
  const [articleOptions, setArticleOptions] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const articleBarcodeRef = useRef(null);
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  // Estados para el modal de asignaciones
  const [asignacionesModalOpen, setAsignacionesModalOpen] = useState(false);
  const [asignaciones, setAsignaciones] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [tiposConteo, setTiposConteo] = useState([]);
  const [conteos, setConteos] = useState([]);
  const [selectedAsignacion, setSelectedAsignacion] = useState(null);
  const [asignacionFormData, setAsignacionFormData] = useState({
    idUsuario: '',
    idConteo: '',
    idTipoConteo: '',
  });

  const socketRef = useRef(null);

  useEffect(() => {
    // Conectar al socket solo una vez
    if (!socketRef.current) {
      socketRef.current = io('https://dicsapps.space:10000');

      socketRef.current.on('conteoDetallesActualizados', (updatedData) => {
        if (Array.isArray(updatedData)) {
          console.log('Datos agrupados actualizados:', updatedData);
          setGroupedData(updatedData);
        } else {
          console.error('Formato de datos inválido recibido para datos agrupados:', updatedData);
        }
      });

      socketRef.current.on('conteoDetallesSinAgruparActualizados', (updatedData) => {
        if (Array.isArray(updatedData)) {
          console.log('Datos desagrupados actualizados:', updatedData);
          setUngroupedData(updatedData);
        } else {
          console.error('Formato de datos inválido recibido para datos desagrupados:', updatedData);
        }
      });

      // Escuchar datos de todos los artículos actualizados
      socketRef.current.on('articulosConteoActualizados', (updatedData) => {
        if (Array.isArray(updatedData)) {
          console.log('Datos de todos los artículos actualizados:', updatedData);
          setAllArticlesData(updatedData);
        } else {
          console.error('Formato de datos inválido recibido para todos los artículos:', updatedData);
        }
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener datos agrupados
        const groupedResponse = await api('/conteo_detalle_analisis');
        const initialGroupedData = groupedResponse.data.conteoDetalles;
        console.log('Datos agrupados iniciales:', initialGroupedData);
        setGroupedData(initialGroupedData);

        if (initialGroupedData.length > 0) {
          const dynamicGroupedColumns = Object.keys(initialGroupedData[0]).map((key) => ({
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            field: key,
            sortable: true,
            filter: true,
            resizable: true,
          }));
          setGroupedColumns(dynamicGroupedColumns);
        }

        // Obtener datos desagrupados
        const ungroupedResponse = await api('/conteo_detalle_sin_agrupacion');
        const initialUngroupedData = ungroupedResponse.data.conteoDetallesSinAgrupar;
        console.log('Datos desagrupados iniciales:', initialUngroupedData);
        setUngroupedData(initialUngroupedData);

        if (initialUngroupedData.length > 0) {
          const dynamicUngroupedColumns = Object.keys(initialUngroupedData[0]).map((key) => ({
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            field: key,
            sortable: true,
            filter: true,
            resizable: true,
          }));
          setUngroupedColumns(dynamicUngroupedColumns);
        }

        // Obtener todos los artículos con información de conteo
        const allArticlesResponse = await api('/articulos_conteo');
        const initialAllArticlesData = allArticlesResponse.data.articulosConteo;
        console.log('Datos de todos los artículos:', initialAllArticlesData);
        setAllArticlesData(initialAllArticlesData);

        if (initialAllArticlesData.length > 0) {
          const dynamicAllArticlesColumns = Object.keys(initialAllArticlesData[0]).map((key) => ({
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            field: key,
            sortable: true,
            filter: true,
            resizable: true,
            cellStyle: (params) => {
              if (key === 'TotalContado' && params.value === 0) {
                return { backgroundColor: '#fdd', color: '#900' }; // Destacar artículos no contados
              }
              return null;
            },
          }));
          setAllArticlesColumns(dynamicAllArticlesColumns);
        }
      } catch (error) {
        console.error('Error al obtener los detalles del conteo:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedBarcode && barcodeRef.current) {
      try {
        console.log('Generando código de barras para:', selectedBarcode);
        JsBarcode(barcodeRef.current, selectedBarcode, {
          format: 'EAN13',
          lineColor: '#000',
          width: 2,
          height: 50,
          displayValue: true,
        });
      } catch (error) {
        console.error('Error al generar el código de barras:', error);
      }
    }
  }, [selectedBarcode]);

  useEffect(() => {
    if (selectedArticle && articleBarcodeRef.current) {
      try {
        console.log('Generando código de barras para el artículo:', selectedArticle.codigoBarras);
        JsBarcode(articleBarcodeRef.current, selectedArticle.codigoBarras, {
          format: 'EAN13',
          lineColor: '#000',
          width: 2,
          height: 50,
          displayValue: true,
        });
      } catch (error) {
        console.error('Error al generar el código de barras del artículo:', error);
      }
    }
  }, [selectedArticle]);

  // Efecto para cargar datos cuando se abre el modal de asignaciones
  useEffect(() => {
    if (asignacionesModalOpen) {
      fetchAsignaciones();
      fetchUsuarios();
      fetchTiposConteo();
      fetchConteos();
    }
  }, [asignacionesModalOpen]);

  const fetchAsignaciones = async () => {
    try {
      const response = await api.get('/asignaciones_conteo');
      if (response.data.success) {
        setAsignaciones(response.data.asignaciones);
      }
    } catch (error) {
      console.error('Error al obtener las asignaciones:', error);
    }
  };

  const fetchUsuarios = async () => {
    try {
      const response = await api.get('/usuarios');
      if (response.data.success) {
        setUsuarios(response.data.usuarios);
      }
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
    }
  };

  const fetchTiposConteo = async () => {
    try {
      const response = await api.get('/tipos_conteo');
      if (response.data.success) {
        setTiposConteo(response.data.tipos || []);
      }
    } catch (error) {
      console.error('Error al obtener los tipos de conteo:', error);
    }
  };

  const fetchConteos = async () => {
    try {
      const response = await api.get('/conteos_lista');
      if (response.data.success) {
        setConteos(response.data.conteos || []);
      }
    } catch (error) {
      console.error('Error al obtener los conteos:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedBarcode('');
    setSelectedRow(null);
  };

  const handleEdit = () => {
    if (selectedRow) {
      setEditFormData(selectedRow);
      setEditModalOpen(true);
    }
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.put(`/conteo_detalle/${editFormData.id}`, {
        cantidadContada: editFormData.cantidadContada,
      });
      if (response.data.success) {
        setEditModalOpen(false);
        alert('Registro actualizado correctamente');
        // La actualización en tiempo real se manejará mediante Socket.IO
      } else {
        alert('Error al actualizar el registro: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error al actualizar el registro:', error);
      alert('Error al conectar con el servidor');
    }
  };

  const handleDelete = () => {
    if (selectedRow) {
      setDeleteConfirmOpen(true);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await api.delete(`/conteo_detalle/${selectedRow.id}`);
      if (response.data.success) {
        setDeleteConfirmOpen(false);
        alert('Registro eliminado correctamente');
        // La actualización en tiempo real se manejará mediante Socket.IO
        setSelectedRow(null);
      } else {
        alert('Error al eliminar el registro: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error al eliminar el registro:', error);
      alert('Error al conectar con el servidor');
    }
  };

  // Funciones para el modal de asignaciones
  const handleOpenAsignacionesModal = () => {
    setAsignacionesModalOpen(true);
  };

  const handleCloseAsignacionesModal = () => {
    setAsignacionesModalOpen(false);
    setSelectedAsignacion(null);
    setAsignacionFormData({
      idUsuario: '',
      idConteo: '',
      idTipoConteo: '',
    });
  };

  const handleAsignacionFormChange = (event) => {
    const { name, value } = event.target;
    setAsignacionFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAsignacionSubmit = async (event) => {
    event.preventDefault();
    try {
      if (selectedAsignacion) {
        // Actualizar asignación existente
        const response = await api.put(`/asignaciones_conteo/${selectedAsignacion.asignacionId}`, {
          idUsuario: asignacionFormData.idUsuario,
          idConteo: asignacionFormData.idConteo,
          idTipoConteo: asignacionFormData.idTipoConteo,
        });
        if (response.data.success) {
          alert('Asignación actualizada correctamente');
          fetchAsignaciones();
          handleCloseAsignacionesModal();
        } else {
          alert('Error al actualizar la asignación: ' + response.data.message);
        }
      } else {
        // Crear nueva asignación
        const response = await api.post('/asignaciones_conteo', {
          idUsuario: asignacionFormData.idUsuario,
          idConteo: asignacionFormData.idConteo,
          idTipoConteo: asignacionFormData.idTipoConteo,
        });
        if (response.data.success) {
          alert('Asignación creada correctamente');
          fetchAsignaciones();
          handleCloseAsignacionesModal();
        } else {
          alert('Error al crear la asignación: ' + response.data.message);
        }
      }
    } catch (error) {
      console.error('Error al guardar la asignación:', error);
      alert('Error al conectar con el servidor');
    }
  };

  const handleEditAsignacion = (asignacion) => {
    setSelectedAsignacion(asignacion);
    setAsignacionFormData({
      idUsuario: asignacion.usuarioId,
      idConteo: asignacion.conteoId,
      idTipoConteo: asignacion.tipoConteoId,
    });
  };

  // Funciones para el modal de búsqueda de artículos
  const handleOpenArticleModal = () => {
    setArticleModalOpen(true);
  };

  const handleCloseArticleModal = () => {
    setArticleModalOpen(false);
    setSelectedArticle(null);
    setArticleOptions([]);
  };

  const fetchArticleOptions = async (value) => {
    setIsLoadingArticles(true);
    try {
      const response = await api.get('/buscar_articulos', {
        params: { query: value },
      });
      if (response.data.success) {
        setArticleOptions(response.data.articulos);
      } else {
        setArticleOptions([]);
      }
    } catch (error) {
      console.error('Error al buscar artículos:', error);
      setArticleOptions([]);
    }
    setIsLoadingArticles(false);
  };

  const handleArticleInputChange = (event, value, reason) => {
    if (reason === 'input') {
      if (value.length >= 2) {
        fetchArticleOptions(value);
      } else {
        setArticleOptions([]);
      }
    }
  };

  const handleArticleSelect = (event, value) => {
    setSelectedArticle(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters sx={{ marginTop: 2 }}>
        <Paper elevation={3} sx={{ padding: 3, marginBottom: 2, backgroundColor: '#f5f5f5' }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Detalles de Conteo
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Datos Agrupados" />
            <Tab label="Datos Desagrupados" />
            <Tab label="Todos los Artículos" />
          </Tabs>
        </Paper>

        {/* Botones de Acción */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, marginRight: 2 }}>
          {tabIndex === 1 && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenArticleModal}
                sx={{ marginRight: 1 }}
              >
                Buscar Artículo
              </Button>
              <Tooltip title={!selectedRow ? 'Selecciona un registro para editar' : ''}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                    disabled={!selectedRow}
                    sx={{ marginRight: 1 }}
                  >
                    Editar
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={!selectedRow ? 'Selecciona un registro para eliminar' : ''}>
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                    disabled={!selectedRow}
                  >
                    Eliminar
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
          {/* Botón para gestionar asignaciones */}
          <Button variant="contained" color="primary" onClick={handleOpenAsignacionesModal}>
            Gestionar Asignaciones
          </Button>
        </Box>

        <Box className="ag-theme-alpine" sx={{ height: '65vh', width: '100%', boxShadow: 3, marginBottom: 4 }}>
          {tabIndex === 0 && (
            <AgGridReact
              rowData={groupedData}
              columnDefs={groupedColumns}
              getRowId={(params) => params.data.codigoBarras || params.data.id}
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                filter: true,
              }}
              headerHeight={45}
              rowHeight={40}
              pagination={true}
              paginationPageSize={20}
              rowSelection="single"
              onSelectionChanged={(event) => {
                const selectedRows = event.api.getSelectedRows();
                if (selectedRows.length > 0 && selectedRows[0].codigoBarras) {
                  console.log('Código de barras seleccionado:', selectedRows[0].codigoBarras);
                  setSelectedBarcode(selectedRows[0].codigoBarras);
                } else {
                  setSelectedBarcode('');
                }
              }}
            />
          )}
          {tabIndex === 1 && (
            <AgGridReact
              rowData={ungroupedData}
              columnDefs={ungroupedColumns}
              getRowId={(params) => params.data.id}
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                filter: true,
              }}
              headerHeight={45}
              rowHeight={40}
              pagination={true}
              paginationPageSize={20}
              rowSelection="single"
              onSelectionChanged={(event) => {
                const selectedRows = event.api.getSelectedRows();
                if (selectedRows.length > 0) {
                  setSelectedRow(selectedRows[0]);
                  if (selectedRows[0].codigoBarras) {
                    setSelectedBarcode(selectedRows[0].codigoBarras);
                  } else {
                    setSelectedBarcode('');
                  }
                } else {
                  setSelectedRow(null);
                  setSelectedBarcode('');
                }
              }}
            />
          )}
          {tabIndex === 2 && (
            <AgGridReact
              rowData={allArticlesData}
              columnDefs={allArticlesColumns}
              getRowId={(params) => params.data.id}
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                filter: true,
                resizable: true,
              }}
              headerHeight={45}
              rowHeight={40}
              pagination={true}
              paginationPageSize={20}
            />
          )}
        </Box>

        {selectedBarcode && (
          <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
            <Typography variant="h6" gutterBottom>
              Código de Barras Seleccionado
            </Typography>
            <svg ref={barcodeRef}></svg>
          </Box>
        )}

        {/* Modal de Edición */}
        <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Editar Registro</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Cantidad Contada"
                    name="cantidadContada"
                    value={editFormData.cantidadContada || ''}
                    onChange={handleEditFormChange}
                    type="number"
                    fullWidth
                    required
                  />
                </Grid>
                {/* Puedes agregar más campos si es necesario */}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditModalOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleEditFormSubmit} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmación de Eliminación */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Confirmar Eliminación</DialogTitle>
          <DialogContent>
            <Typography>
              ¿Estás seguro de que deseas eliminar este registro?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button onClick={confirmDelete} color="primary">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal de Búsqueda de Artículos */}
        <Dialog open={articleModalOpen} onClose={handleCloseArticleModal} maxWidth="md" fullWidth>
          <DialogTitle>Buscar Artículo</DialogTitle>
          <DialogContent>
            <Autocomplete
              freeSolo
              options={articleOptions}
              getOptionLabel={(option) => `${option.codigoBarras} - ${option.descripcion}`}
              onInputChange={handleArticleInputChange}
              onChange={handleArticleSelect}
              loading={isLoadingArticles}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar Artículo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingArticles ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {selectedArticle && (
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="h6">Detalles del Artículo</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography><strong>Código de Barras:</strong> {selectedArticle.codigoBarras}</Typography>
                    <Typography><strong>Descripción:</strong> {selectedArticle.descripcion}</Typography>
                    <Typography><strong>Unidad de Medida:</strong> {selectedArticle.unidadMedida}</Typography>
                    {/* Agrega más campos si es necesario */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      Código de Barras
                    </Typography>
                    <svg ref={articleBarcodeRef}></svg>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseArticleModal} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal de Asignaciones */}
        <Dialog open={asignacionesModalOpen} onClose={handleCloseAsignacionesModal} maxWidth="md" fullWidth>
          <DialogTitle>Gestionar Asignaciones</DialogTitle>
          <DialogContent>
            {/* Lista de asignaciones existentes */}
            <Typography variant="h6">Asignaciones Existentes</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Conteo</TableCell>
                  <TableCell>Tipo de Conteo</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asignaciones.map((asignacion) => (
                  <TableRow key={asignacion.asignacionId}>
                    <TableCell>{asignacion.nombreUsuario}</TableCell>
                    <TableCell>{asignacion.nombreConteo}</TableCell>
                    <TableCell>{asignacion.nombreTipo}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEditAsignacion(asignacion)}
                        sx={{ marginRight: 1 }}
                      >
                        Editar
                      </Button>
                      {/* Si deseas agregar eliminación, puedes agregar un botón aquí */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Formulario para crear o editar una asignación */}
            <Typography variant="h6" sx={{ marginTop: 3 }}>
              {selectedAsignacion ? 'Editar Asignación' : 'Nueva Asignación'}
            </Typography>
            <form onSubmit={handleAsignacionSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Usuario"
                    name="idUsuario"
                    value={asignacionFormData.idUsuario}
                    onChange={handleAsignacionFormChange}
                    fullWidth
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Seleccione un usuario</option>
                    {usuarios.map((usuario) => (
                      <option key={usuario.id} value={usuario.id}>
                        {usuario.nombreUsuario}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Conteo"
                    name="idConteo"
                    value={asignacionFormData.idConteo}
                    onChange={handleAsignacionFormChange}
                    fullWidth
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Seleccione un conteo</option>
                    {conteos.map((conteo) => (
                      <option key={conteo.id} value={conteo.id}>
                        {conteo.nombreConteo}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Tipo de Conteo"
                    name="idTipoConteo"
                    value={asignacionFormData.idTipoConteo}
                    onChange={handleAsignacionFormChange}
                    fullWidth
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Seleccione un tipo de conteo</option>
                    {tiposConteo.map((tipo) => (
                      <option key={tipo.id} value={tipo.id}>
                        {tipo.nombreTipo}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={handleCloseAsignacionesModal} color="secondary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  {selectedAsignacion ? 'Actualizar' : 'Guardar'}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default ConteoDetalleTable;
