import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';
import { Container, TextField, Button, Typography, Box, Card, CardContent } from '@mui/material';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await api.post('/login', { username, password });
      if (response.data.success) {
        navigate('/dashboard'); // Navigate to dashboard on success
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError('Error en el servidor. Inténtelo de nuevo más tarde.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card sx={{ boxShadow: 4, borderRadius: 3 }}>
        <CardContent>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom>
              Inicio de Sesión
            </Typography>
          </Box>
          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}>
              Iniciar Sesión
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LoginPage;
