import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';

const UserManagement = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch users from the server
    axios.get('/api/users')
      .then(response => {
        setUsers(response.data.users.map(user => ({
          id: user.id,
          username: user.nombreUsuario,
          role: user.rol_id
        })));
      
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleDeleteUser = (userId) => {
    // Delete user by id
    axios.delete(`/api/users/${userId}`)
      .then(response => {
        setUsers(users.filter(user => user.id !== userId));
      })
      .catch(error => {
        console.error('Error deleting user:', error);
      });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 4, p: 4, bgcolor: '#f7f9fc', borderRadius: 3, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: '#0d47a1' }}>
          Gestión de Usuarios
        </Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} sx={{ bgcolor: '#0d47a1', '&:hover': { bgcolor: '#094a9a' }, fontWeight: 'bold' }}>
          Añadir Usuario
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography sx={{ fontWeight: 'bold' }}>Nombre de Usuario</Typography></TableCell>
              <TableCell><Typography sx={{ fontWeight: 'bold' }}>ID del Rol</Typography></TableCell>
              <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }}>Acciones</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => console.log('Editar usuario', user.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default UserManagement;
